import React, { useEffect } from "react";
import { Container, Row } from "reactstrap";
import Header from "./Header";
import Footer from "./Footer";

const RefundPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container fluid>
            <Row>
                <Header />
                <div className="topDivider">
                    <p className={`PageTitleText light p-2 m-0 ${window.innerWidth <= 768 ? 'fs-24' : 'fs-30'}`}>Refund & Cancellation Policy</p>
                </div>
            </Row>
            <Row>
                <div className={`py-3 fs-16 ${window.innerWidth <= 768 ? 'px-4 text-center' : 'px-5'}`} style={{ background: "#F6F6F6", borderBottom: "1px solid #d1d1d1" }}>
                    <div className="pb-4">
                        <p>Our focus is complete customer satisfaction. In the event, if you are displeased with the services provided, provided the reasons are genuine and proved after investigation Our Policy for the cancellation and refund will be as follows:
                        </p>
                        <p>Agreeing to our terms and conditions when you create an account with jainshadimilan.com or by an app means you agree to our Cancellation and refund policy.
                        </p>
                        <p>If any Issue related to our services please mail us on info@jainshadimilan.com or call us on 7581839333.
                        </p>
                    </div>
                    <div>
                        <h5 className="pb-3">Cancellation Policy: - </h5>
                        <p>For Cancellations please contact us via contact us link. Requests received within one day prior to the start of your services will be treated as cancellation of services.
                        </p>
                    </div>
                    <div>
                        <h5 className="pb-2">Refund Policy -</h5>
                        <p>We will try our best to provide suitable Services for our clients. Any refunds issued will be processed and completed within 14 days of the initial refund request. Please read the full terms for our refund policy below
                        </p>
                    </div>
                    <p>1. jainshadimilan.com will NOT refund ANY payment to ANY member for ANY reason whatsoever EXCEPT in the case of error on jainshadimilan.com's part.
                    </p>
                    <p>2. If paid by credit card, refunds will be issued to the original credit card provided at the time of purchase and in case of payment gateway name payments refund will be made to the same account.
                    </p>
                    <p>3. jainshadimilan.com will not refund any member who has decided that they no longer wish to use jainshadimilan.com. A refund can NOT be given in part or whole for any subscription used or not used by any member for whatever reason. Users who wish to cancel their subscription are not permitted to seek a partial or full refund for any reason.
                    </p>
                    <p>Please pay attention to the following rules which if broken, will result in a PERMANENT BAN from the jainshadimilan.com site and app WITHOUT A REFUND:</p>
                    <p>1. Anyone found to be using the jainshadimilan.com website or app under the age of 18 will be banned immediately without refund and your IP address will also be banned.
                    </p>
                    <p>2. Anyone found to be using the jainshadimilan.com website or app from any other community other than jain will be banned immediately without refund and your IP address will also be banned.</p>
                    <p> 3. You have registered as a member of jainshadimilan website or app for the purpose of finding a spouse OR you are a parent, relative, family and friend who has registered on behalf of you WITH your PERMISSION. You may not assign or otherwise transfer your account to any other person or entity. </p>
                    <p>4. You commit to keep all information provided to you by other members of jainshadimilan website or app private and confidential and you will obtain the permission of the member who provided it to you prior to sharing it with a third party.
                    </p>
                    <p>5. You agree to include FACTUAL information about yourself which is a TRUE and ACCURATE representation of yourself. You may NOT use fake pictures (actors, actresses, models or any person who is not the actual member.) and any other misleading or untruthful personal Information, such as misrepresented real age, location, country of residence, country of origin, religion, height, weight, and any other item of personal description can result in a permanent ban without refund.</p>
                    <p>6. Abuse to staff will NOT be tolerated in any way, shape of form, and will result in a permanent ban without refund.</p>
                    <p>7. It is YOUR responsibility to communicate with members in a polite, respectful manner. Rude, offensive, filthy, disgusting or vulgar messages or any other inappropriate messages will result in a permanent ban without refund</p>
                    <p>8. A refund can NOT be given on the basis of members choosing not to correspond with you. Reasons for members failing to contact you include: poorly written profile, personal preferences and wants do not match yours, your profile may not be appealing to others, your personal circumstances may mean your profile is not of interest to others etc. This is by no means an exhaustive list, but in all such occasions, you CANNOT be refunded.
                    </p>
                    <p>9. It is YOUR responsibility to CANCEL your membership when you are no longer interested in being a member of jainshadimilan.com. Jain Shadi Milan will NOT refund any members who have failed to do so.</p>
                    <p>10. Please note that ALL members of jainshadimilan.com – whether free or paid MUST adhere to the rules mentioned herein these terms and conditions. Failure to do so can result in a ban without refund.
                    </p>
                    <p>11. Profiles are approved within 24 hours after the all steps of registration process completed and any profiles deemed suspicious or fraudulent will be rejected immediately with a permanent ban and NO refund.
                    </p>
                    <p>12. You will not engage in gathering personal information such as email addresses, telephone numbers, addresses, financial information or any other kind of information of our members.
                    </p>
                    <p>13. You will not request money, or any other form of financial assistance, from any member that you meet on this site – any attempt to do so may result in your details being shared with legal teams, lawyers, the Police or any other Government or legal bodies for the purpose of an investigation.
                    </p>
                    <p>14. You will not send money or any form of financial asset to any other member that you meet on this site– any attempt to do so may result in your details being shared with legal teams, lawyers, the Police or any other Government or legal bodies for the purpose of an investigation.
                    </p>
                    <p>15. You will not organize and will not participate in any funds transfer or any asset transfer arrangement organized by any member you meet on this site– any attempt to do so may result in your details being shared with legal teams, lawyers, the Police or any other Government or legal bodies for the purpose of an investigation.
                    </p>
                    <p>16. REFUNDS are NOT permitted for members who have paid in full and then wish to take a discounted offer or promotion instead. You may NOT cancel your membership to buy a cheaper deal, as your IP address is recorded and we reserve the right to ban your account on this basis. Please note that all monthly subscriptions automatically rebill at the end of the initial subscription term – this is shown CLEARLY to you on screen when you create your account.
                    </p>
                    <p>17. You will not load or distribute any virus or software which may harm the site or any of its users.</p>
                    <p>18. You will not create multiple user accounts for yourself.</p>
                    <p>19. You will not post in your profile or communicate through any conversations vulgar material, pictures or content of an Un-Islamic nature.
                    </p>
                    <p>20. You will not post in your profile or communicate through any conversations any material promoting harmful or criminal thoughts, intent or action.
                    </p>
                    <p>21. You will not post in your profile or communicate through any conversations any form of harassment, sexual or otherwise, racism, bigotry, violence, invasion of privacy, suggesting, engaging in or enticing other to commit any illegal or immoral acts. Such actions can be followed up and prosecuted as necessary.
                    </p>
                    <p>22. You will not send to any member unsolicited mass mailings, junk mail, spam mail and chain letters, nor will you send any business information with the intent to engage or sell to other members including asking members to sign up to other websites or any other medium for products and services.</p>
                    <p>23. Any member found to be trying to find ways to evade using our site conversation area and trying to post their personal details to take conversations off the site will be banned without refund.</p>
                    <p>24. Any member requesting to take conversations off the Jain Shadi Milan website when compatibility has not been established can be subject to a permanent ban without refund.</p>
                    <h4 className="py-3">- Jain Shadi Milan</h4>
                </div>
                <Footer />
            </Row>
        </Container>
    )
};

export default RefundPolicy;
