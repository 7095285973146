import React from "react";
import { Button, Col, Container, Input, Row } from "reactstrap";
import lang from "../assets/images/Language.png";
import logo from "../assets/images/Logo.png"
import Footer from "./Footer";
import PlaceIcon from '@mui/icons-material/Place';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
const Contact = () => {
    return (
        <Container fluid>
            <Row className="contactUsStyle pb-5">
                <Col className="p-0"><img className="logoImg" src={logo} alt="" /></Col>
                <Col className="p-2 text-end">
                    <img src={lang} alt="" height="30" className="pe-4" />
                    <Button className="logInBtn">Log In</Button>
                </Col>
                <div>  <p className="light fs-40 my-2 text-center fw-500">Contact Us</p> </div>
                <Row>
                    <Col className="displayCenterProp mb-5">
                        <div>
                            <div className="d-flex justify-content-start flex-row my-3">
                                <span className="iconStyle">
                                    <PlaceIcon className={`primaryColor ${window.innerWidth <= 768 ? 'fs-20' : 'fs-45'}`} />
                                </span>
                                <div className="ps-4">
                                    <p className="m-0 primaryColor">Address</p>
                                    <p className="light m-0">Vishistha Building, In front Agrawal School,<br />
                                        Teen Gulli Chouraha, Damoh - M.P.</p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-start flex-row my-4">
                                <span className="iconStyle">
                                    <LocalPhoneIcon className={`primaryColor ${window.innerWidth <= 768 ? 'fs-20' : 'fs-45'}`} />
                                </span >
                                <div className="ps-4">
                                    <p className="m-0 primaryColor">Phone</p>
                                    <a href="tel:7610447511"><span className="light">+91 76104 47511</span></a><br />
                                    <a href="tel:7610447512"><span className="light">+91 76104 47512</span></a>
                                </div>

                            </div>
                            <div className="d-flex justify-content-start flex-row my-4">
                                <span className="iconStyle">
                                    <EmailIcon className={`primaryColor ${window.innerWidth <= 768 ? 'fs-20' : 'fs-45'}`} />
                                </span >
                                <div className="ps-4">
                                    <p className="m-0 primaryColor">Mail</p>
                                    <p className="light">Vishistha Building, In front Agrawal School,<br />
                                        Teen Gulli Chouraha, Damoh - M.P.</p>
                                </div>

                            </div>
                        </div>


                    </Col>

                    <Col className="displayCenterProp mb-5">
                        <div className="secondaryBg p-3 px-4" style={{
                            width: window.innerWidth <= 768 ? "80%" : "65%"
                        }}>
                            <h2 className="py-2">Enquiry</h2>
                            <Input className="contactInputStyle" type="text" placeholder="Full Name" required />
                            <Input className="contactInputStyle" type="email" placeholder="Email Address" />
                            <Input className="contactInputStyle" type='number' placeholder="Phone" />
                            <Input className="contactInputStyle" type='text' placeholder="City" />
                            <Input className="contactInputStyle" type='text' placeholder="Message" />
                            <div className="text-center my-4">
                                <Button className="contactSubmit">Submit</Button>
                            </div>

                        </div>
                    </Col>
                </Row>
            </Row>
            <div className="row"><Footer /></div>

        </Container>
    )
};

export default Contact;
