import React, { useEffect } from "react";
import { Container, Row } from "reactstrap";
import Header from "./Header";
import Footer from "./Footer";

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container fluid>
            <Row>
                <Header />
                <div className="topDivider">
                    <p className={`PageTitleText light p-2 m-0 ${window.innerWidth <= 768 ? 'fs-24' : 'fs-30'}`}>Privacy Policy</p>
                </div>
            </Row>
            <Row>
                <div className={`py-3 fs-16 ${window.innerWidth <= 768 ? 'px-4 text-center' : 'px-5'}`} style={{ background: "#F6F6F6", borderBottom: "1px solid #d1d1d1" }}>
                    <h6 className="py-3">Jain Shadi Milan - PRIVACY POLICY</h6>
                    <p>Jain Shadi Milan (the “Company”) is committed to maintaining robust privacy protections for its users. Our Privacy Policy (“Privacy Policy”) is designed to help you understand how we collect, use and safeguard the information you provide to us and to assist you in making informed decisions when using our Service.
                    </p>
                    <p>For purposes of this Agreement, “Site” refers to the Company’s website and app, which can be accessed at JainShadiMilan.in [or through our mobile application]. “Service” refers to the Company’s services accessed via the Site or app, in which users can avail the matrimony services. The terms “we,” “us,” and “our” refer to the Company. “You” refers to you, as a user of our Site, app or our Service. By accessing our Site, app or our Service, you accept our Privacy Policy and Terms of Use, and you consent to our collection, storage, use and disclosure of your Personal Information as described in this Privacy Policy.
                    </p>
                    <h6>I. INFORMATION WE COLLECT</h6>
                    <p>We collect “Non-Personal Information” and “Personal Information.” Non-Personal Information includes information that cannot be used to personally identify you, such as anonymous usage data, general demographic information we may collect, referring/exit pages and URLs, platform types, preferences you submit and preferences that are generated based on the data you submit and a number of clicks. Personal Information we gather from members and visitors who apply for the various services our website/apps offers includes, but may not be limited to, email address, name, date of birth, educational qualifications a user-specified password, mailing address, zip/pin code and telephone/mobile number or fax number.
                    </p>
                    <p>We use a secure server for credit card transactions to protect the credit card information of our users and Cookies are used to store the login information. Cookies are small files placed on your hard drive that will assist us in providing our services. You may also encounter Cookies or identical/related devices on certain pages of the website/apps that are placed by third parties. We do not control the use of cookies by third parties.</p>
                    <p>If you establish a credit account with us to pay the fees we charge, some additional information, including a billing address, a credit/debit card number and a credit/debit card expiration date and tracking information from cheques or demand drafts is collected.
                    </p>
                    <p> The user information we collect depends on the context of your interactions with us and the website or Apps, the choices you make and the products and features you use. The User Information is used for authentication and account access, If a user registers using social networking platforms such as Facebook, Google, LinkedIn and others we may collect personal data you choose to allow us to access through their APIs. When the user accesses our websites or apps, data relating to device ID, log files, Geographic Location, device Information/specification are also collected automatically
                    </p>
                    <p>We may use also your personal information for analysis of data, usage trends and to evaluate and improve our site/App, marketing research, preventing of frauds. In our efforts to continually improve our product and service offerings, we collect and analyse demographic and profile data about our users' activity on our website/apps. We identify and use your IP address to help diagnose problems with our server, and to administer our website/apps. Your IP address is also used to help identify you and to gather broad demographic information.
                    </p>
                    <p>Why does our mobile application require permissions from you? <br />
                        Our services help connect users to their matches based on several parameters and preferences. To fix bugs within the application and improve the user experience, we require users to grant us access to the following:
                    </p>
                    <h6>Camera:</h6>
                    <p>Access to the device's camera allows users to take their picture, and upload them as their profile picture on our portal
                    </p>
                    <h6>Contacts:</h6>
                    <p>The mobile application will obtain the user accounts that are associated with Facebook or Google+ for a quick signup process.</p>
                    <h6>Location:</h6>
                    <p>Based on their GPS coordinates, users are served with profiles of members located in the same vicinity. Also, users are provided with the information of our physical stores that are located nearest to the user's GPS coordinates.</p>
                    <h6>Phone:</h6>
                    <p>Our mobile application is optimized to work on multiple devices and networks. The device information and network details help analyze and fix issues that are isolated to a particular device or a particular network.
                    </p>
                    <h6>SMS:</h6>
                    <p>The mobile application detects SMS(s) and auto-fills the OTP / PIN details for a faster and better user experience.</p>
                    <h6>Storage:</h6>
                    <p>The users can upload pictures from their device memory and use them as their profile picture. Furthermore, users can download horoscope details, profile details and Astro-match results on to their device memory.</p>
                    <p>How the website/apps use the information it collects/tracks? <br />
                        We collects information for data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our websites or apps, products, and services, marketing research from our users primarily to ensure that we can fulfil your requirements and to deliver Personalised experience.</p>
                    <h6>1. Information collected via Technology</h6>
                    <p>To activate the Service you do need toprovide the above mention services. However, to improve the quality of the Service, we track information provided to us by your browser or by our software application when you view or use the Service, such as the website you came from (known as the “Jainshadimilan.in”), the type of browser you use, the device from which you connected to the Service, the time and date of access, and other information that does not personally identify you. We track this information using cookies, or small text files which include an anonymous unique identifier. Cookies are sent to a user’s browser from our servers and are stored on the user’s computer hard drive. Sending a cookie to a user’s browser enables us to collect Non-Personal information about that user and keep a record of the user’s preferences when utilizing our services, both on an individual and aggregate basis. For example, the Company may use cookies to collect the some information.
                    </p>
                    <p>The Company may use both persistent and session cookies; persistent cookies remain on your computer after you close your session and until you delete them, while session cookies expire when you close your browser. [For example, we store a persistent cookie to track your userid which may be already saved by the google.
                    </p>
                    <h6>2. Information you provide us by registering for an account</h6>
                    <p>In addition to the information provided automatically by your browser when you visit the Site, to become a subscriber to the Service you will need to create a personal profile. You can create a profile by registering with the Service and entering your email address and creating a user name and a password. By registering, you are authorizing us to collect, store and use your email address by this Privacy Policy.
                    </p>
                    <h6>3. Children’s Privacy</h6>
                    <p>Being a matrimony site, we allow people with age 18 and above. We do not promote or support child marriage or forced or forged account in any case, if descrepencies found any the account of the user will be ceased and legal action can also be taken for further extent.
                    </p>
                    <h6>I. HOW WE USE AND SHARE INFORMATION Personal Information:</h6>
                    <p>Except as otherwise stated in this Privacy Policy, we do not sell, trade, rent or otherwise share for marketing purposes your Personal Information with third parties without your consent. We share Personal Information with vendors who are performing services for the Company, such as the servers for our email communications who are provided access to user’s email address to send emails from us. Those vendors use your Personal Information only at our direction and following our Privacy Policy.
                    </p>
                    <p>In general, the Personal Information you provide to us is used to help us communicate with you. For example, we use Personal Information to contact users in response to questions, solicit feedback from users, provide technical support, and inform users about promotional offers.
                    </p>
                    <p>We may share Personal Information with outside parties if we have a good-faith belief that access, use, preservation or disclosure of the information is reasonably necessary to meet any applicable legal process or enforceable governmental request; to enforce applicable Terms of Service, including investigation of potential violations; address fraud, security or technical concerns; or to protect against harm to the rights, property, or safety of our users or the public as required or permitted by law.</p>
                    <h6>Non-Personal Information:</h6>
                    <p>In general, we use Non-Personal Information to help us improve the Service and customize the user experience. We also aggregate Non-Personal Information to track trends and analyze use patterns on the Site. This Privacy Policy does not limit in any way our use or disclosure of Non-Personal Information and we reserve the right to use and disclose such Non-Personal Information to our partners, advertisers and other third parties at our discretion.</p>
                    <p>n the event we undergo a business transaction such as a merger, acquisition by another company, or sale of all or a portion of our assets, your Personal Information may be among the assets transferred. You acknowledge and consent that such transfers may occur and are permitted by this Privacy Policy and that any acquirer of our assets may continue to process your Personal Information as outlined in this Privacy Policy. If our information practices change at any time in the future, we will post the policy changes to the Site so that you may opt-out of the new information practices. We suggest that you check the Site periodically if you are concerned about how your information is used.
                    </p>
                    <h6>II. HOW WE PROTECT INFORMATION :</h6>
                    <p>We implement security measures designed to protect your information from unauthorized access. Your account is protected by your account password and we urge you to take steps to keep your personal information safe by not disclosing your password and by logging out of your account after each use. We further protect your information from potential security breaches by implementing certain technological security measures including encryption, firewalls and secure socket layer technology. However, these measures do not guarantee that your information will not be accessed, disclosed, altered or destroyed by breach of such firewalls and secure server software. By using our Service, you acknowledge that you understand and agree to assume these risks.</p>
                    <h6>III. YOUR RIGHTS REGARDING THE USE OF YOUR PERSONAL INFORMATION :</h6>
                    <p>You have the right at any time to prevent us from contacting you for marketing purposes. When we send a promotional communication to a user, the user can opt-out of further promotional communications by following the unsubscribe instructions provided in each promotional e-mail.</p>
                    <h6>IV. CHANGES TO OUR PRIVACY POLICY :</h6>
                    <p>The Company reserves the right to change this policy and our Terms of Service at any time. We will notify you of significant changes to our Privacy Policy by sending a notice to the primary email address specified in your account or by placing a prominent notice on our site. Significant changes will go into effect 30 days following such notification. Non-material changes or clarifications will take effect immediately. You should periodically check the Site and this privacy page for updates.</p>
                    <h6>V. CONTACT US :</h6>
                    <p>If you have any questions regarding this Privacy Policy or the practices of this Site, please contact us by sending an email to [Insert Company Email]. Last Updated: This Privacy Policy was last updated on [Insert Date].</p>
                    <h4>    - Jain Shadi Milan</h4>
                </div>
                <Footer />
            </Row>
        </Container>
    )
};

export default PrivacyPolicy;
