import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";

const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Header />
            <div className="row topDivider">
                <p className={`PageTitleText light p-2 m-0 ${window.innerWidth <= 768 ? 'fs-24' : 'fs-30'}`}>About Us</p>
            </div>
            <div className={`py-3 ${window.innerWidth <= 768 ? 'px-4 text-center' : 'px-5'}`} style={{ background: "#F6F6F6", borderBottom: "1px solid #d1d1d1" }}>
                <h4 className="py-3">Jai Jinendra !</h4>
                <p className={`pe-3 ${window.innerWidth <= 768 ? 'fs-16' : 'fs-20'}`}>You will be very happy to know that “Jain Online Patrika” has been established to find suitable partners for young men and women of the Jain Community. Jain Shadi Milan App is a “jain online patrika” developed only for Jain Community. The “Jain Online Patrika” will be in the form of an app in which you will be able to find your soul mate!Jain Shadi Milan app is designed keeping in mind various partner preferences that a candidate might have while choosing their life partner. Our Jain Shadi Milan app is designed to give you the best results to match you with your life partner. We have member registrations from all sects and subsects of the Jain community. We also have profile registrations from outside India.Your privacy is a top priority for us at all times.Jain Shadi Milan App Features: Safe and secure app with privacy. User-friendly preference for easy partner search. Smart list management system to shortlist favorite candidates.Convenient filters to get potential matches of your interest. Save your time and money.All of you are requested to further support the initiative taken by this us for the Jain community for Jain society. If there are any Jain marriageable young men and women in your family and relatives , then get them registered too.</p>
                <h4>    - Jain Shadi Milan</h4>
            </div>
            <Footer />
        </div>
    )
};

export default About;
