import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import Constant from "../Constant";
import moment from "moment";
import { getProfileById } from "../Services/Service";
import demoImg from "../../src/assets/images/BannerImg.jpg"
import { Helmet } from "react-helmet";
const UserProfile = () => {
    const { userId } = useParams()
    const [userData, setUserData] = useState();

    useEffect(() => {
        getProfileByIDTemp(userId)
    }, []);

    const getProfileByIDTemp = useCallback(async (id) => {
        const res = await getProfileById(id);
        if (res?.data?.status) {
            setUserData(res?.data?.data)
            updateFavicon(`${Constant?.appBaseUrl}/${res?.data?.data?.profiles[0]?.folder}/${res?.data?.data?.profiles[0]?.name}`);
        }
    }, [userId]);

    const updateFavicon = (url) => {
        const link = document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'icon';
        link.href = url;
        document.head.appendChild(link);
    };

    return (
        <div>
            <Helmet>
                <meta property="og:title" content={userData?.nameEnglish || "Default Title"} />
                <meta
                    name="description"
                    content={userData?._age !== null
                        ? `${userData?._age} Years`
                        : `${moment().diff(userData?.dob, "years")} Years`}
                />
                <meta property="og:image" content={`${Constant?.appBaseUrl}/${userData?.profiles[0]?.folder}/${userData?.profiles[0]?.name}`} />
            </Helmet>

            <div className="position-relative">
                <img className="userImgStyle" src={demoImg} alt="" />

                {/* <div className="content-Box">
                    <p className="fs-12 fw-500 light m-0">JSM - {userData?.id}</p>
                    <p className="fs-16 fw-500 light m-0">{userData?.nameHindi} | {userData?._age}</p>
                    <p className="fs-16 fw-500 light m-0">{userData?.first_formMaritalStatusEnglish} | {userData?.third_formSectEnglish}</p>
                    <p className="fs-16 fw-500 light m-0">{userData?.second_formStateEnglish} | {userData?.first_formGenderEnglish}</p>
                </div> */}
            </div>
        </div>
    )
};

export default UserProfile;
