import React from "react";
import insta from "../assets/images/insta.png";
import fb from "../assets/images/fb.png";
import snap from "../assets/images/snap.png";
import ytube from "../assets/images/Ytube.png";
import ws from "../assets/images/Wp.png";
import footerLogo from '../assets/images/Group 75.png';
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className="row py-3 justify-content-center">
            <div className={`col-lg-3 col-md-5 col-sm-12 p-0 footercenter ${window.innerWidth <= 1025 ? 'ps-3' : 'ps-5'}`}>
                <a href="https://jainshadimilan.com/"><img src={footerLogo} height="50" className="pe-2" alt="footer logo" /><span className="fs-20 primaryColor fw-500 my-2">Jain Shadi Milan</span></a>
                <p className="m-0 fs-16"><a href="tel:+7610447512">+91-7610447512</a></p>
                <p className="m-0 fs-16"><a href="mailto:info@jainshadimilan.com"></a>info@jainshadimilan.com</p>
                <p className="m-0 fs-16">Vishisht Building Teen gulli, Infront of Agrawal School,</p>
                <p className="mb-3 fs-16">Damoh, Madhya Pradesh - 470661</p>
                <div>
                    <a href="https://www.instagram.com/jainshadimilan?igsh=Y2l0YXA1cnc1cWp3"><img src={insta} alt="" /></a>
                    <a href="https://www.facebook.com/jainshadi.milan?mibextid=ZbWKwL"><img src={fb} alt="" /></a>
                    <a href="https://youtube.com/@jainshadimilan?feature=shared"><img src={ytube} alt="" /></a>
                    <Link to="https://wa.me/7583831008"> <img src={ws} alt="" /></Link>
                </div>
            </div>
            <div className={`col-lg-2 col-md-3 col-sm-12 ${window.innerWidth <= 767 ? 'text-center' : ''}`}>
                <p className="fs-20 primaryColor fw-500 my-2">JSM</p>
                <Link to="/about">
                    <p className="fs-16">About Us</p>
                </Link>
            </div>
            <div className={`col-lg-3 col-md-4 col-sm-12 ${window.innerWidth <= 767 ? 'text-center' : ''}`}>
                <p className="fs-20 primaryColor fw-500 my-2">Privacy & Help</p>
                <Link to="/terms">
                    <p className="fs-16">Terms & Conditions</p>
                </Link>
                <p className="fs-16">Registration Fees</p>
                <Link to="/refund-policy">
                    <p className="fs-16">Refund & Cancellation Policy</p>
                </Link>
            </div>
            <div className={`col-lg-2 col-md-6 col-sm-12 ${window.innerWidth <= 767 ? 'text-center' : ''}`}>
                <p className="fs-20 primaryColor fw-500 my-2">Sing Up /Sing In</p>
                <p className="fs-16">Free registration</p>
                <p className="fs-16">Member login</p>
            </div>
            <div className={`col-lg-2 col-md-6 col-sm-12 ${window.innerWidth <= 767 ? 'text-center' : ''}`}>
                <p className="fs-20 primaryColor fw-500 my-2">Help</p>
                <Link to="/contact">
                    <p className="fs-16">Contact Us</p>
                </Link>
                <Link to="/privacy-policy">
                    <p className="fs-16">Privacy Policy</p>
                </Link>
                <Link to="/terms">
                    <p className="fs-16">Terms & Conditions</p>
                </Link>
            </div>
            <hr />
            <p className={`ps-5 m-0 ${window.innerWidth <= 767 ? 'text-center' : ''}`} style={{ fontSize: "12px" }}>All rights reserved©2020 jainshadimilan.com | This website is strictly for matrimonial purpose only.</p>
        </div>
    )
};

export default Footer;
