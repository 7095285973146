import React from "react";
import { Button, Col, Container, Row } from "reactstrap";
import logo from "../assets/images/Logo.png"
import lang from "../assets/images/Language.png";
import { Link } from "react-router-dom";

const Header = () => {
    return (
        <div>
            <Container fluid>
                <Row>
                    <Col className="p-0">
                        <a href="https://www.jainshadimilan.com/"><img className="logoImg" src={logo} alt="" /></a></Col>
                    <Col className="p-2 d-flex justify-content-end align-items-center">
                        <img src={lang} alt="" height="30" className="pe-4" />
                        <Link to="https://mobile.jainshadimilan.com/"><Button>Log In</Button></Link>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

export default Header;
