import React, { useState } from "react";
import logo from "../assets/images/Logo.png"
import { Button, Col, Container, Row } from "reactstrap";
import arrowright from "../assets/images/ArroeRight.svg";
import arrow from "../assets/images/arrow.svg";
import ReactPlayer from 'react-player'
import socialIcon from '../assets/images/social-care 1.png'
import resumeIcon from '../assets/images/resume 1.png'
import filterIcon from '../assets/images/filter 1.png'
import handShake from '../assets/images/handshake 1.png'
import shield from '../assets/images/shield 1.png'
import regImg from "../assets/images/registrationImg.png";
import verifyImg from "../assets/images/verificationImg.png";
import searchImg from "../assets/images/seachImg.png";
import MeetImg from "../assets/images/MeetImg.png";
import lang from "../assets/images/Language.png";
import Footer from "../Pages/Footer";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import iphone from '../assets/images/iphone.png'
import FemaleSlider from "../Components/OurRegisterSlider/FemaleSlider";
import MaleSlider from "../Components/OurRegisterSlider/MaleSlider";
import { login } from "../Services/Service";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
const Home = () => {
    const [mobValue, setMobValue] = useState("")

    const handleRegister = async () => {
        if (mobValue?.length < 10) {
            alert("Enter Correct Number")
        }
        else {
            let obj = {
                mobile: mobValue,
                password: "",
            };
            const res = await login(obj);
        }
    };
    return (
        <div>
            <Container fluid>
                <Row className="bannerStyle d-flex justify-content-center">
                    <Col className="p-0">
                        <a href="https://www.jainshadimilan.com/"><img className="logoImg" src={logo} alt="" /></a></Col>
                    <Col className="p-2 text-end">
                        <img src={lang} alt="" height="30" className="pe-4" />
                        <Link to="https://mobile.jainshadimilan.com/"><Button className="logInBtn">Log In</Button></Link>
                    </Col>
                    <div className="m-0 text-center" style={{ height: window.innerWidth <= 767 ? "80px" : "170px" }}>
                        <div className="mainHeading">जैन समाज का सबसे बड़ा Online परिचय App</div>
                        <div className="secHeading">जैनों की शादी जैनों से...</div>
                    </div>
                    <div className="row secondaryBg registerSec p-0">
                        <div className="col-lg-4 displayCenterProp freeRegistr">
                            <Link to="https://mobile.jainshadimilan.com/signin">
                                <Button className={`RegisterBtn ${window.innerWidth <= 768 ? 'fs-20' : 'fs-24'}`}><ArrowBackIcon style={{ marginRight: "10px" }} />Login Now</Button></Link>
                        </div>
                        <div className="col-lg-4 text-center journeySec d-flex flex-column justify-content-center">
                            <p className={`m-0 primaryColor  ${window.innerWidth <= 768 ? 'fs-26' : 'fs-32'}`}>Start Your Journey</p>
                            <p className={`m-0   ${window.innerWidth <= 768 ? 'fs-20' : 'fs-24'}`}>By Just One Simple Step</p>
                        </div>
                        {/* <div className="col-lg-4 inputSec d-flex flex-column justify-content-center">
                            <p className={`my-1 fs-20 ${window.innerWidth <= 768 ? 'text-left' : ''}`} style={{ fontWeight: "500" }}>Mobile Number</p>
                            <input className="inputStyle" placeholder="Enter Your Mobile Number" type="number" value={mobValue} onChange={(e) => { setMobValue(e?.target.value) }}></input>
                        </div> */}
                        <div className="col-lg-4 displayCenterProp freeRegistr">
                            <Link to="https://mobile.jainshadimilan.com/signin">
                                <Button className={`RegisterBtn ${window.innerWidth <= 768 ? 'fs-20' : 'fs-24'}`} >Register For Free <ArrowForwardIcon /></Button></Link>
                        </div>
                    </div>
                </Row>
                <div className="row p-4 primaryBg">
                    <div className={` ps-3   ${window.innerWidth <= 768 ? 'col-sm-12 text-center' : 'col-lg-6'}`}>
                        <p className="light text-center py-2 m-0" style={{ fontSize: '35px', fontFamily: "Tiro Devanagari Hindi", fontWeight: "400" }}>जैन शादी मिलन क्या है ??</p>
                        <p className={`light mb-4   ${window.innerWidth <= 768 ? 'fs-16 text-center' : 'fs-20'}`}>Jai Jinendra ! You will be very happy to know that “Jain Online Patrika” has been established to find suitable partners for young men and women of the Jain Community. Jain Shadi Milan App is a “jain online patrika” developed only for Jain Community. The “Jain Online Patrika” will be in the form of an app in which you will be able to find your soul mate!
                            Jain Shadi Milan app is designed keeping in mind various partner preferences that a candidate might have while choosing their life partner. Our Jain Shadi Milan app is designed to give you the best results to match you with your life partner.
                        </p>
                        <Link to="/about"> <Button className="ReadBtn">Read More</Button></Link>
                    </div>
                    <div className="col-lg-6 col-sm-12 d-flex flex-column align-items-center">
                        <p className={`light py-2  ${window.innerWidth <= 768 ? 'fs-24 text-center' : 'fs-32'}`}>How To Register ??</p>
                        {/* <VideoSlider /> */}
                        <ReactPlayer url={'https://www.youtube.com/watch?v=XViJqpwMPTc&ab_channel=JainShadiMilan'} height="233.474px" width="370px" controls={true} />
                    </div>
                </div>
                <div className="row py-4">
                    <div className="reasonSecRgt">
                        <div className="row reasonSec" >
                            <div className={`d-flex flex-column align-items-center ${window.innerWidth <= 768 ? 'my-1' : 'my-4'}`} style={{ height: "120px" }}>
                                <span className={`fw-500 primaryColor ${window.innerWidth <= 768 ? 'fs-32 text-center' : 'fs-48'}`}>Why Jain Shadi Milan</span>
                                <span className="Sub-Heading" >You have various reason to choose us</span>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <div className='row'>
                                    <div className={` ${window.innerWidth <= 768 ? 'col-3 pt-2' : 'col-4 text-end'}`}>
                                        <img src={socialIcon} alt="" />
                                    </div>
                                    <div className={`col mb-3 ${window.innerWidth <= 768 ? 'p-0' : 'ps-5'}`}>
                                        <p className={`my-2 fw-500 ${window.innerWidth <= 768 ? 'fs-24' : 'fs-30 '}`}>जैनों के द्वारा जैनों के लिये</p>
                                        <p className={` ${window.innerWidth <= 768 ? 'fs-16' : 'fs-18'}`}>यह जैनों के द्वारा जैनों के लिये बनाया गया app है ,
                                            <br /> इसलिए बहुत ही Trustful App है |</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={` ${window.innerWidth <= 768 ? 'col-3 pt-2' : 'col-4 text-end'}`}>
                                        <img src={resumeIcon} alt="" />
                                    </div>
                                    <div className={`col mb-3 ${window.innerWidth <= 768 ? 'p-0' : 'ps-5'}`}>
                                        <p className={`my-2 fw-500 ${window.innerWidth <= 768 ? 'fs-24' : 'fs-30 '}`}>सिर्फ Verified जैन बायोडेटा</p>
                                        <p className={` ${window.innerWidth <= 768 ? 'fs-16' : 'fs-18'}`}>इस App पर आपको केवल जैन समाज के <br /> Verified बयोडेटा ही मिलेंगे | </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={` ${window.innerWidth <= 768 ? 'col-3 pt-2' : 'col-4 text-end'}`}>
                                        <img src={filterIcon} alt="" />
                                    </div>
                                    <div className={`col mb-3 ${window.innerWidth <= 768 ? 'p-0' : 'ps-5'}`}>
                                        <p className={`my-2 fw-500 ${window.innerWidth <= 768 ? 'fs-24' : 'fs-30 '}`}>Filter सुविधा</p>
                                        <p className={` ${window.innerWidth <= 768 ? 'fs-16' : 'fs-18'}`}>इसमें आप Age, Area, Profession, के <br /> आकॉर्डिंग जैन बायोडाटा देख सकते है |</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <div className="row">
                                    <div className={` ${window.innerWidth <= 768 ? 'col-3 pt-2' : 'col-2 text-end'}`}>
                                        <img src={handShake} alt="" />
                                    </div>
                                    <div className={`col mb-3 ${window.innerWidth <= 768 ? 'p-0' : 'ps-5'}`}>
                                        <p className={`my-2 fw-500 ${window.innerWidth <= 768 ? 'fs-24' : 'fs-30 '}`}>सकल जैन समाज एकता</p>
                                        <p className={` ${window.innerWidth <= 768 ? 'fs-16' : 'fs-18'}`}>हमारा उद्देश्य सकल जैन समाज को जोड़ <br /> कर एक प्लेटफार्म पर लाना है |</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={` ${window.innerWidth <= 768 ? 'col-3 pt-2' : 'col-2 text-end'}`}>
                                        <img src={shield} alt="" />
                                    </div>
                                    <div className={`col mb-3 ${window.innerWidth <= 768 ? 'p-0' : 'ps-5'}`}>
                                        <p className={`my-2 fw-500 ${window.innerWidth <= 768 ? 'fs-24' : 'fs-30 '}`}>विश्वसनीय app</p>
                                        <p className={` ${window.innerWidth <= 768 ? 'fs-16' : 'fs-18'}`}>यह जैनों का सबसे विश्वसनीय App है |</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row primaryBg sliderSec text-center py-4">
                    <>
                        <p className={`light ${window.innerWidth <= 768 ? 'fs-30 text-center' : 'fs-48'}`}>Our Registered Males</p>
                        <MaleSlider />
                    </>
                    <div>
                        <p className={`light ${window.innerWidth <= 768 ? 'fs-30 text-center' : 'fs-48'}`}>Our Registered Females</p>
                        <FemaleSlider />
                    </div>
                </div>

                <div className="py-4">
                    <div className={`d-flex flex-column align-items-center  ${window.innerWidth <= 768 ? 'my-2 justify-content-center' : 'my-4'}`} style={{ height: "120px" }}>
                        <span className={`primaryColor fw-500 ${window.innerWidth <= 768 ? 'fs-32 text-center' : 'fs-48'}`}>Easy Registration</span>
                        <span className={`${window.innerWidth <= 768 ? 'fs-18 text-center' : 'fs-24'}`}>Find Your Perfect Match In 4 Easy Steps</span>
                    </div>
                    <div className="row d-flex justify-content-center align-items-center text-center">
                        <div className="col-lg-2 col-md-5 col-sm-12">
                            <img className="my-3" src={regImg} alt="" style={{ marginRight: "23px" }} />
                            <div><Button className="regBtns" outline>Registration</Button></div>
                        </div>
                        <div className="col-1" style={{ alignSelf: "center" }}>
                            {window.innerWidth <= 600 ? null : <img src={arrow} alt="" />}
                        </div>
                        <div className="col-lg-2 col-md-5  col-sm-12">
                            <img className="my-3" src={verifyImg} alt="" style={{ marginRight: "35px" }} />
                            <div>  <Button className="regBtns" outline>Verification</Button> </div>
                        </div>
                        <div className="col-1" style={{ alignSelf: "center" }}>
                            {window.innerWidth <= 600 ? null : <img src={arrow} alt="" />}
                        </div>
                        <div className="col-lg-2 col-md-5  col-sm-12">
                            <img src={searchImg} alt="" />
                            <div><Button className="regBtns" outline>Search</Button></div>
                        </div>
                        <div className="col-1" style={{ alignSelf: "center" }}>
                            {window.innerWidth <= 600 ? null : <img src={arrow} alt="" />}
                        </div>
                        <div className="col-lg-2 col-md-5  col-sm-12">
                            <img src={MeetImg} alt="" />
                            <div><Button className="regBtns" outline>Meet</Button></div>
                        </div>
                    </div>
                </div>

                <div className="row displayCenterProp whatsappStyle py-3">
                    <div className="col-lg-6 col-sm-12 text-center">
                        <Link to="https://wa.me/7583831008">
                            <Button className="btn whatsappBtn my-4 px-3">WhatsApp Group <WhatsAppIcon className="fs-38" /> </Button>
                        </Link>
                        <p className={`fw-500 ${window.innerWidth <= 768 ? 'fs-24 ' : 'fs-38'}`} style={{ fontFamily: "Laila" }}>WhatsApp Group से जुडने के लिये</p>
                        <div className={`displayCenterProp numStyle light my-4 ${window.innerWidth <= 768 ? 'fs-24 ' : 'fs-38'}`}>
                            <span className="px-2 primaryBg" style={{ borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px", borderRight: '2px solid' }}>
                                <a href="tel:+7583831008" className="numberlinkstyle">7583831008</a>
                            </span>
                            {/* <span className="px-2 primaryBg" style={{ borderTopRightRadius: "5px", borderBottomRightRadius: "5px" }}>
                                <a href="tel:+7610447512" className="numberlinkstyle">7610447512</a>
                            </span> */}
                        </div>
                        <p className={` ${window.innerWidth <= 768 ? 'fs-24 ' : 'fs-38'}`} style={{ fontFamily: "Laila", fontWeight: "600" }}>WhatsApp पर “जय जिनेंद्र” लिखकर भेजें |</p>
                    </div>
                    <div className="col-lg-6 col-sm-12 text-center"><img src={iphone} className="phoneImgHeight" /></div>
                </div>
                {/* Footer Section */}
                <Footer />
            </Container >
        </div >
    );
};

export default Home;
