import React from "react";
import { Route, Routes, useNavigate, useRoutes } from "react-router";
import Home from "./Pubilc/Home";
import '../src/theme/Style.css'
import '../src/theme/Common.css'
import '../src/theme/variables.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import TermsCondition from "./Pages/TermsCondition";
import RefundPolicy from "./Pages/RefundPolicy";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import UserProfile from "./Components/UserProfile";

const allRoutes = [
  {
    path: "/",
    element: <Home />,
  },
  { path: "about", element: < About /> },
  { path: "contact", element: < Contact /> },
  { path: "terms", element: < TermsCondition /> },
  { path: "refund-policy", element: < RefundPolicy /> },
  { path: "privacy-policy", element: < PrivacyPolicy /> },
  { path: "users/:userId", element: < UserProfile /> },
  {
    path: "register",
    element: <RedirectToRegistration />,
  },
]
function RedirectToRegistration() {
  const navigate = useNavigate();
  React.useEffect(() => {
    window.location.href = "https://mobile.jainshadimilan.com/";
  }, []);

  return null; // No content to render
}
function App() {
  let element = useRoutes(allRoutes);
  return element
}

export default App;
